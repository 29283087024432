import { cloneDeep } from 'lodash'
import LogRocket from 'logrocket'

import { sha256 } from './hasher'
import { redactObject } from './redactObject'

const REQUEST_WHITELISTED_FIELDS = [
  'accept',
  'content-length',
  'content-type',
  'contenttype',
  'credentials',
  'duration',
  'entryType',
  'initiatorType',
  'method',
  'mode',
  'name',
  'referrer',
  'reqId',
  'startTime',
  'transferSize',
  'url',
  // Middleware related fields
  '__typename',
  'Active__c',
  'Amount__c',
  'apollographql-client-name',
  'apollographql-client-version',
  'channel',
  'Description__c',
  'entityid',
  'HSACard__c',
  'Label',
  'operationName',
  'Payment_Profile__c',
  'paymentType',
  'PaymentType__c',
  'query',
  'Schedule_Date__c',
  'Source_URL__c',
  'Type__c',
  'UTM_Campaign__c',
  'UTM_Medium__c',
  'UTM_Source__c',
  'UTM_Term__c',
  'Web_MD_Domain__c',
].map((key) => key.toLowerCase())

const RESPONSE_WHITELISTED_FIELDS = [
  'cf-cache-status',
  'cf-ray',
  'content-type',
  'method',
  'message',
  'reqId',
  'server',
  'status',
  'x-transaction-id',

  // /authenticate endpoint related fields
  'balance',
  'estimatedPaidThrough',
  'id',
  // Middleware related fields
  '__typename',
  'Active__c',
  'Amount__c',
  'Description__c',
  'HSACard__c',
  'Id',
  'Label',
  'Name',
  'Payment_Profile__c',
  'paymentType',
  'PaymentType__c',
  'Schedule_Date__c',
  'Source_URL__c',
  'Type__c',
  'UTM_Campaign__c',
  'UTM_Medium__c',
  'UTM_Source__c',
  'UTM_Term__c',
  'Web_MD_Domain__c',
].map((key) => key.toLowerCase())

/**
 * Initialize LogRocket, run network request/response sanitization, & identify
 *
 * @param prefix unknown field string
 * @param identifier unknown field string
 */
export const initLogRocket = async (prefix: string, identifier: string) => {
  LogRocket.init('lf5hnf/mdvip', {
    shouldCaptureIP: false,
    network: {
      requestSanitizer: (request) => {
        const obj = cloneDeep(request)
        if (obj.body) {
          try {
            const body = JSON.parse(obj.body)
            obj.body = body
            // eslint-disable-next-line no-empty
          } catch {}
        }
        return redactObject(obj, REQUEST_WHITELISTED_FIELDS, false)
      },
      responseSanitizer: (response) => {
        const obj = cloneDeep(response)
        if (obj.body) {
          try {
            const body = JSON.parse(obj.body)
            obj.body = body
            // eslint-disable-next-line no-empty
          } catch {}
        }
        return redactObject(obj, RESPONSE_WHITELISTED_FIELDS, false)
      },
    },
  })
  const hashed = await sha256(identifier)
  LogRocket.identify(`${prefix}-${hashed}`)
}
